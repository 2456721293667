<template>
	<div id="rootVM">
		<i class="fa fa-spinner fa-spin"></i>
	</div>
</template>

<script>
import VueCookies from 'vue-cookies';
import apiIndex from '../../api/index';
import axios from 'axios';

const loginUrl = apiIndex.login;

export default {
	async created() {
		await this.login();
		await this.getCommCodeList();
		await this.movePage();
	},
	methods: {
		// 로그인
		async login() {
			const jwtToken = this.$route.query.token;
			const siteId = this.$route.query.siteId;

			VueCookies.set('V2_ACCESS_TOKEN', jwtToken);
			await axios.post(loginUrl.setUserSiteId, { siteId });
		},
		async getCommCodeList() {
			const commonCodeList = JSON.parse(sessionStorage.getItem('commonCodeList'));
			if (!commonCodeList || commonCodeList.length == 0) {
				await axios.post(apiIndex.commCode.inqCommCodeAllList, {}).then(
					function (r) {
						sessionStorage.setItem('commonCodeList', JSON.stringify(r.data));
					}.bind(this),
				);
			}
		},
		async movePage() {
			const dest = this.$route.path.replaceAll(/\/kccRestApi\//g, '');

			switch (dest) {
				case 'viewBannerDangerWarnWorkOccur.do':
					this.$axios.post(apiIndex.dangerWarnWorkOccur.viewDangerWarnWorkOccur, {}).then(res => {
						sessionStorage.setItem('dangerWarnWorkOccurPageParam', JSON.stringify({ ...res.data, ...this.$route.query, videoDangerActnDvsnCd: '' }));
						location.href = '/danger-warn-work-occur';
					});
					break;
				default:
					location.href = '/new-main';
			}
		},
	},
};
</script>

<style scoped>
#rootVM {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

#rootVM i {
	font-size: 3em;
	margin: auto;
}
</style>
